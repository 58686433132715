<template>
<v-container id="portal-management" tag="section">
  <base-material-card color="indigo" icon="mdi-book-open-page-variant-outline" inline class="px-5 py-3">
    <template v-slot:after-heading>
      <div class="display-2 font-weight-light">
        {{item.name}}
      </div>
    </template>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img contain :max-height="300" :src="item.imageURL"></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <h3 class="text-left">{{item.summary}}</h3>
        <h5 class="text-left">{{item.content}}</h5>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="internalVideos.length >= 1">
      <v-col cols="12" class="text-center">
        <h1 class="text-center">Videos</h1>
        <template v-if="internalVideos.length === 1">
          <v-container>
            <v-row dense class="text-center">
              <v-col v-for="(link, i) in internalVideos" :key="i" cols="12" md="6" class="mx-auto">
                <vueVimeoPlayer v-if="link.content !== undefined" :videoUrl="`https://vimeo.com/` + link.content" :videoId="link.content" :options="options"></vueVimeoPlayer>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-if="internalVideos.length >= 2">
          <v-container>
            <v-row dense>
              <v-col v-for="(link, i) in internalVideos" :key="i" cols="12" md="4" sm="12">
                <vueVimeoPlayer v-if="link.content !== undefined" :videoUrl="`https://vimeo.com/` + link.content" :videoId="link.content" :options="options"></vueVimeoPlayer>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>
    </v-row>
    <v-row v-if="gridLinks.length !== 0">
      <v-col cols="12">
        <h1 class="text-center">Helpful Links and Documents</h1>
        <v-container>
          <v-row dense>
            <v-col v-for="(link, i) in gridLinks" :key="i" cols="12" md="3" sm="6">
              <v-card outlined elevation="1">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      <span v-if="link.type === 1 || link.type === 2">External Link</span>
                      <span v-if="link.type === 3">Document</span>
                      <span v-if="link.type === 5">External Video</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ link.description.substring(0,50) }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="60">
                    <v-icon x-large v-if="link.type === 1 || link.type === 2">mdi-link-variant</v-icon>
                    <v-icon x-large v-if="link.type === 3">mdi-file-document</v-icon>
                    <v-icon x-large v-if="link.type === 5">mdi-youtube</v-icon>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn outlined rounded text small :href="link.content" target="_blank">
                    See Resource
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </base-material-card>
</v-container>
</template>

<script>
import ItemDetails from '@/Services/Marketing/ResourceDetailsCatalogue'
import {
  vueVimeoPlayer
} from 'vue-vimeo-player'
export default {
  name: 'MarketingDetails',
  components: {
    vueVimeoPlayer
  },
  data: () => ({
    options: {
      responsive: true,
      autoplay: false
    },
    item: {},
    gridLinks: [],
    internalVideos: []
  }),
  created () {
    const itemID = this.$route.params.id
    this.initialize(itemID)
  },
  methods: {
    async initialize (id) {
      await ItemDetails.getDetails(id)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'item', response.data)
            this.item.links.forEach(element => {
              if (element.type !== 4) {
                this.gridLinks.push(element)
              } else if (element.type === 4) {
                this.internalVideos.push(element)
              }
            })
          }
        })
    }
  }
}
</script>
